.container {
  margin: auto;
  background-color: white;
  z-index: 4;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
}

.row-without-img-and-x-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .row-without-img-and-x-block {
    flex-direction: row;
  }
  .container {
    padding: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    font-size: 14px;
  }
}

.row-amount-quantity-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 5px auto 5px auto;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.header-title {
  text-align: center;
  color: black;
  font-size: 20px;
  font-weight: 600;
}

.header-close-btn {
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px dotted var(--hover-link-color);
}

.row:hover,
.name:hover {
  color: blue;
}

/* .image {
  width: 100%;
} */

.name,
.name-not-available {
  width: 100%;
  text-align: start;
  color: black;
  margin-left: 20px;
  padding-right: 20px;
}

.name-not-available {
  color: red;
}

.name:hover {
  text-decoration: underline;
}

.amount-row,
.amount-row-not-available {
  font-size: 12px;
  color: var(--bg-prim);
  text-align: center;
  width: 55px;
  padding: 0px 5px;
}

.amount-row-not-available {
  color: red;
  font-size: 12px;
}

.none-items {
  text-align: center;
  padding: 30px;
}

.fotter {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.amount,
.amount-in,
.amount-order {
  align-self: center;
  font-size: 16px;
  font-weight: 600;
  margin-left: auto;
  margin-right: 0px;
  color: var(--bg-prim);
}

.amount-order {
  color: black;
}

.amount-in {
  font-size: 12px;
  color: black;
  margin-right: 20px;
  font-style: italic;
}

.quantity-block {
  /* display: flex; */
  /* width: 15%; */
  /* align-items: baseline; */
  /* vertical-align: middle; */
  margin: auto 5px;
}

.quantity-input,
.quantity-input-order {
  width: 30px;
  padding: 1px;
  text-align: center;
  font-size: 11px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  margin: 0px 2px;
}

.quantity-input-order {
  width: 40px;
  color: black;
  border: 1px solid gray;
  border-radius: 0px;
}

.quantity-button {
  width: 25px;
  padding-top: 3px;
  text-align: center;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border: 0px solid var(--primary-color);
  border-radius: 5px;
  cursor: pointer;
}

.quantity-button:hover {
  background-color: var(--hover-link-color);
}

.delete-x {
  color: var(--bg-prim);
  cursor: pointer;
}

.delete-x-order {
  color: gray;
  cursor: pointer;
  margin-right: 5px;
}

.delete-x-order-block {
  width: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  color: white;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  margin-right: 5px;
}

/* .modal-basket-container { 
  position: absolute;
  top: 0;
  left: 0;
  width: 98%;
  height: 100%;
  transform: translate(1%, 25%);
  z-index: 99;
}

@media screen and (min-width: 800px) {
  .modal-basket-container {
    width: 75%;
    height: 75%;
    transform: translate(16%, 29%);
  }
}
@media screen and (min-width: 1200px) {
  .modal-basket-container {
    width: 50%;
    height: 50%;
    transform: translate(50%, 20%);
  }
} */

/* @media screen and (min-width: 1200px) {
  .modal-basket-container {
    width: 50%;
    height: 50%;
    transform: translate(50%, 20%);
  }
} */

.modal-basket-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 98%;
  height: 100%;
  transform: translate(1%, 25%);
  z-index: 99;
}

@media screen and (min-width: 800px) {
  .modal-basket-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    height: 75%;
    transform: translate(16%, 29%);
    z-index: 99;
  }
}
@media screen and (min-width: 1200px) {
  .modal-basket-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    height: 75%;
    transform: translate(16%, 29%);
    z-index: 99;
  }
}

.btn-buy-container {
  display: flex;
  margin-left: 3px;
  min-width: 97px;
  align-items: center;
}

.btn-buy {
  height: 25px;
  background-color: var(--bg-prim);
  fill: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
}

.btn-buy-svg {
  position: relative;
  display: flex;
  align-items: center;
}

.quantity_circle {
  position: absolute;
  bottom: 7px;
  right: 13px;
  user-select: none;
}

.price-retail,
.price-retail-max {
  margin-left: 0px;
  margin-right: 5px;
  padding: 1px;
  font-size: 17px;
  color: var(--bg-prim);
  cursor: pointer;
  user-select: none;
}

.price-retail-max {
  color: grey;
  text-decoration: line-through;
}

table,
th,
td {
  border: 0.5px solid grey;
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  font-size: 11px;
  color: black;
}

.input-price {
  width: 60px;
  font-size: 12px;
  border: 1px solid black;
  padding: 2px 3px;
  text-align: center;
}

.delivery-container {
  margin-top: 5px;
  font-size: 12px;
  padding-top: 15px;
  border-top: 1px solid rgb(235, 235, 235);
}

.form-group {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  width: 370px;
  margin: 5px;
}

.form-control {
  width: 100%;
  margin-top: 5px;
  padding: 3px;
  border: 1px solid var(--border-color);
  background-color: rgb(232, 240, 254);
}

.delivery-item {
  display: flex;
  padding: 0px 0px 20px 0px;
  margin-left: 20px;
  width: 370px;
  cursor: pointer;
}

.delivery-label {
  padding-left: 5px;
}

.return-success-link {
  margin-top: 30px;
  text-decoration: underline;
  cursor: pointer;
}

.return-success-link:hover {
  color: blue;
}

@media screen and (min-width: 1050px) {
  .delivery-block {
    display: flex;
  }
}

.table-cell {
  padding: 2px 8px;
  height: 30px;
  text-align: center;
  font-size: 12px;
}

.np-error {
  color: red;
  margin-left: 20px;
}

.none-items-error {
  text-align: center;
  padding: 30px;
  color: red;
}
